import React from 'react';
import { graphql } from 'gatsby';
import DemoRequestPage from 'components/pages/DemoRequestPage';

const seo = {
  title:
    'Póngase en contacto con nuestro equipo y disfrute de una demostración personalizada',
  description:
    '¿Tiene preguntas sobre Q°emotion? Póngase en contacto con nuestro equipo para obtener más información y disfrutar de una demostración de nuestra solución',
  keywords: [],
};

export default props => <DemoRequestPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    textBlockData: markdownRemark(
      frontmatter: {
        pageType: { eq: "demoRequest" }
        blockType: { eq: "textBlock" }
        lang: { eq: "es" }
      }
    ) {
      html
    }
    formBlockData: markdownRemark(
      frontmatter: {
        pageType: { eq: "demoRequest" }
        blockType: { eq: "formBlock" }
        lang: { eq: "es" }
      }
    ) {
      html
    }
    dataTestimonialBlock: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "solutionMarketing" }
          blockType: { eq: "testimonialBlock" }
          lang: { eq: "es" }
        }
      }
      sort: { order: ASC, fields: [frontmatter___order] }
      limit: 1
    ) {
      nodes {
        html
        rawMarkdownBody
        frontmatter {
          author
          role
          image
        }
      }
    }
    imageTestimonialBlock: allFile(
      filter: { relativeDirectory: { eq: "bubbleImages" }, base: { in: [] } }
      sort: { fields: [id], order: [ASC] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            presentationWidth
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    imagesQuotesTestimonialBlock: allFile(
      filter: {
        relativeDirectory: {
          eq: "solutionPage/analyseVerbatim/testimonialBlock"
        }
      }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataBlockLinkBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "clientCase" }
          blockType: { eq: "linkBlock" }
          lang: { eq: "es" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          subTitle
          color
          link
          buttonText
        }
      }
    }
    dataMessage: markdownRemark(
      frontmatter: { pageType: { eq: "confirmDemo" }, lang: { eq: "es" } }
    ) {
      rawMarkdownBody
    }
    imagesLogosBlock: allFile(
      filter: {
        relativeDirectory: { eq: "indexPage/subIntroBlock/blockClients" }
        base: {
          in: [
            "maif.png"
            "tui.png"
            "loreal.png"
            "credit_agricole.png"
            "heineken_experience_green.png"
            "belambra.png"
          ]
        }
      }
      sort: { fields: [base] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 120, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
  }
`;
